import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, catchError, throwError } from "rxjs";

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any) => {
        console.error(err);
        if (err && err.error && err.error.message) {
          return throwError(() => new Error(err.error.message));
        } else if (err && err.message) {
          return throwError(() => new Error(err.message));
        }

        return throwError(() => err);
      })
    );
  }
}
