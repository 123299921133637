<!-- Only render this if there's an authenticated user -->
<ng-container *ngIf="authenticator.route === 'authenticated'">
  <router-outlet></router-outlet>
</ng-container>

<!-- Render sign-in screen otherwise with authenticator -->
<ng-container *ngIf="authenticator.route !== 'authenticated'">
  <app-header></app-header>
  <div class="grid-container">
    <amplify-authenticator [loginMechanisms]="['email']"></amplify-authenticator>
  </div>
</ng-container>
