import { Injectable } from '@angular/core';
import ZoomVideo, { VideoClient, Stream, SubsessionClient } from '@zoom/videosdk';
import { isAndroidBrowser, isSupportOffscreenCanvas, isSupportWebCodecs } from '../utils/platform';
import { LoggerService } from './logger/logger.service';

export type ZoomClient = typeof VideoClient;
export type ZoomSubsessionClient = typeof SubsessionClient;
export type ZoomVideoStream = typeof Stream;

@Injectable({
  providedIn: 'root',
})
export class ZoomVideosdkService {
  private _isInitialized = false;

  client: ZoomClient | undefined;

  constructor(private logger: LoggerService) {
    ZoomVideo.preloadDependentAssets();
  }

  get ssClient() {
    return this.client?.getSubsessionClient();
  }

  get isSupportWebCodecs() {
    return isSupportWebCodecs();
  }
  get isUseVideoElementToDrawSelfVideo() {
    return isAndroidBrowser() || isSupportOffscreenCanvas();
  }

  get isCurrentUserHost() {
    return this.client?.getCurrentUserInfo()?.isHost;
  }

  get participants() {
    return this.client?.getAllUser() || [];
  }

  get isEvenParticipants() {
    const len = this.participants.length || 0;
    return len > 0 && len % 2 === 0;
  }

  get currentUser() {
    return this.client?.getCurrentUserInfo();
  }

  createClient() {
    this.client = ZoomVideo.createClient();
    window.zsdkClient = this.client;
    return this.client;
  }

  async initClient() {
    try {
      if (this._isInitialized) return;

      if (!this.client) {
        throw new Error('Client not initialized');
      }
      this.logger.debug('Initializing Zoom Video SDK');
      await this.client.init('en-US', 'CDN', {
        enforceMultipleVideos: false,
        stayAwake: true,
      });
      this._isInitialized = true;
    } catch (err: any) {
      this.logger.error('Error initializing Zoom Video SDK', err);
      throw err;
    }
  }
}
