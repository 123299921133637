import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoggerService, LogLevel, LOG_LEVEL } from './logger.service';

export function loggerServiceFactory(level: LogLevel) {
  return new LoggerService(level);
}

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
})
export class LoggerModule {
  static forRoot(level: LogLevel = LogLevel.Info): ModuleWithProviders<LoggerModule> {
    return {
      ngModule: LoggerModule,
      providers: [
        { provide: LOG_LEVEL, useValue: level },
        { provide: LoggerService, useFactory: loggerServiceFactory, deps: [LOG_LEVEL] },
      ],
    };
  }
}
