<mat-card>
  <mat-card-header>
    <mat-card-title (click)="gotoEventsPage()" class="cursor-pointer">Fast Match</mat-card-title>
    <mat-card-subtitle class="lowercase">v{{ version }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="authenticator?.user?.attributes?.email as email" class="flex justify-between items-center">
      <span class="text-ellipsis overflow-hidden">{{ email }}</span>
      <button mat-button *ngIf="!!authenticator.user" (click)="authenticator.signOut()" class="shrink-0">
        <mat-icon fontIcon="logout"></mat-icon>
        Logout
      </button>
    </div>
  </mat-card-content>
</mat-card>
