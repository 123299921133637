import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'events',
    pathMatch: 'full',
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then((m) => m.EventsPageModule),
  },
  {
    path: 'event/:eventId',
    loadChildren: () => import('./pages/event/event.module').then((m) => m.EventPageModule),
  },
  {
    path: 'create-event',
    loadChildren: () => import('./pages/create-event/create-event.module').then((m) => m.CreateEventPageModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'grid-view-test',
    loadChildren: () => import('./pages/grid-view-test/grid-view-test.module').then((m) => m.GridViewTestModule),
  },

  // redirect to events page if no route found
  {
    path: '**',
    redirectTo: 'forbidden',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
