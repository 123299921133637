import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, Observable, shareReplay } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatCardModule],
})
export class AppHeaderComponent implements OnInit {
  username: string | undefined;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );
  version = environment.version;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public authenticator: AuthenticatorService,
  ) {}

  async ngOnInit() {}

  logout() {
    this.router.navigate(['/']);
  }

  gotoEventsPage() {
    this.router.navigate(['/events']);
  }
}
