import { Injectable, InjectionToken, Optional } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { CaptureContext } from '@sentry/types';

export enum LogLevel {
  Off = 0,
  Error = 1,
  Warn = 2,
  Info = 3,
  Log = 4,
  Debug = 5,
  Trace = 6,
}
export const COLOR_SCHEME = [
  '', // off
  'red', // error
  'orange', // warn
  'teal', // info
  'teal', // log
  'gray', // debug
  'purple', // trace
];

export const LOG_LEVEL = new InjectionToken<LogLevel>('logLevel');

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private level: LogLevel = LogLevel.Info;

  constructor(@Optional() level: LogLevel) {
    if (level !== undefined) {
      this.level = level;
    }
  }

  error(message: any, ...optionalParams: any[]) {
    if (this.level <= LogLevel.Error) {
      this.onLog(LogLevel.Error, message, ...optionalParams);
    }
  }

  warn(message: any, ...optionalParams: any[]) {
    if (this.level <= LogLevel.Warn) {
      this.onLog(LogLevel.Warn, message, ...optionalParams);
    }
  }

  info(message: any, ...optionalParams: any[]) {
    if (this.level <= LogLevel.Info) {
      this.onLog(LogLevel.Info, message, ...optionalParams);
    }
  }

  log(message: any, ...optionalParams: any[]) {
    if (this.level <= LogLevel.Log) {
      this.onLog(LogLevel.Log, message, ...optionalParams);
    }
  }

  debug(message: any, ...optionalParams: any[]) {
    if (this.level <= LogLevel.Debug) {
      this.onLog(LogLevel.Debug, message, ...optionalParams);
    }
  }

  trace(message: string, ...optionalParams: any[]) {
    if (this.level <= LogLevel.Trace) {
      this.onLog(LogLevel.Trace, message, ...optionalParams);
    }
  }

  safePromiseCatchHandler = (err: any) => {
    this.error(err);
  };

  private onLog(level: LogLevel, message: string | Error, ...optionalParams: any[]) {
    const color = COLOR_SCHEME[level];
    switch (level) {
      case LogLevel.Error:
        console.error('%c[ERROR]', `color:${color}`, message, ...optionalParams);
        this.onCaptureError(message, ...optionalParams);
        break;
      case LogLevel.Warn:
        console.warn('%c[WARN]', `color:${color}`, message, ...optionalParams);
        break;
      case LogLevel.Info:
        console.info('%c[INFO]', `color:${color}`, message, ...optionalParams);
        break;
      case LogLevel.Log:
        console.log('%c[LOG]', `color:${color}`, message, ...optionalParams);
        break;
      case LogLevel.Debug:
        // console.debug('[DEBUG]', message, ...optionalParams);
        console.debug(`%c[DEBUG]`, `color:${color}`, message, ...optionalParams);
        // this.onCaptureMessage(message as string, ...optionalParams);
        break;
      case LogLevel.Trace:
        console.trace('%c[TRACE]', `color:${color}`, message, ...optionalParams);
        break;
    }
  }

  private onCaptureError(message: string | Error, ...optionalParams: any[]) {
    let error: Error | undefined;
    if (message instanceof Error && message.stack !== undefined) {
      error = message;
    }
    if (!error) {
      optionalParams.forEach((param) => {
        if (!error) {
          if (param instanceof Error && param.stack !== undefined) {
            error = param;
          }
        }
      });
    }

    const context: CaptureContext = {
      extra: {
        message,
        meta: optionalParams,
      },
    };

    Sentry.captureException(error || message, context);
  }

  private onCaptureMessage(message: string, ...optionalParams: any[]) {
    const context: CaptureContext = {
      extra: {
        message,
        meta: optionalParams,
      },
    };

    Sentry.captureMessage(message, context);
  }
}
