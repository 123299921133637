import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';

export enum InterceptorSkipHeader {
  Authoriztaion = 'X-Skip-Header-Authoriztaion',
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(Auth.currentSession().catch(_ => null)).pipe(
      switchMap(session => {
        const token = session?.getIdToken()?.getJwtToken();
        if (!req.headers.has(InterceptorSkipHeader.Authoriztaion)) {
          if (token) {
            req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
          }
        } else {
          req = req.clone({ headers: req.headers.delete(InterceptorSkipHeader.Authoriztaion) });
        }
        if (!req.headers.has('Content-Type')) {
          req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }
        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        return next.handle(req);
      }),
    );
  }
}
