import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { lowerCase } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}

  public async isAuthenticated(): Promise<boolean> {
    try {
      await Auth.currentSession();
      return true;
    } catch {
      return false;
    }
  }

  public async getUser(): Promise<any> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch {
      return null;
    }
  }

  public async getUserAttributes(attrs: 'email' | 'username'): Promise<any> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = {
        email: user.attributes.email,
        username: user.username,
      };
      return attributes?.[attrs];
    } catch {
      return null;
    }
  }

  public async getCognitoGroups(): Promise<any> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user.signInUserSession.accessToken.payload['cognito:groups'] || [];
    } catch {
      return null;
    }
  }

  public async hasGroups(groups: string[]): Promise<boolean> {
    try {
      const userGroups = await this.getCognitoGroups();
      return groups.map(lowerCase).some((group) => userGroups.map(lowerCase).includes(group));
    } catch {
      return false;
    }
  }
}
