import packageJson from '../../package.json';

export const environment = {
  production: true,
  version: packageJson.version,
  api: 'https://grrujcm4w0.execute-api.ap-southeast-2.amazonaws.com/dev',
  ws: 'wss://k6ktpz3cb8.execute-api.ap-southeast-2.amazonaws.com/dev',
  auth: {
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_BMLmtt6qb',
    userPoolWebClientId: '6u1jmkas9gh9cohq3p752dnuvb',
    identityPoolRegion: 'ap-southeast-2',
    identityPoolId: 'ap-southeast-2:d4cfa732-228d-40a6-b719-5883d84d4ebe',
  },
};
