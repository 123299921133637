import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
// import { HttpClient as HttpClientIntegration } from '@sentry/integrations';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { isLocalhost } from './app/utils/platform';

Sentry.init({
  dsn: 'https://d84afb9f79424a8481f160fb7ebe62ab@o4504194067333120.ingest.sentry.io/4504857206521856',
  enabled: !isLocalhost(),
  environment: 'development',
  release: environment.version,
  integrations: [
    new BrowserTracing({
      // tracePropagationTargets: ["localhost", "https://yourserver.io/api"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new CaptureConsoleIntegration({
      levels: ['error'],
    }),
    // new HttpClientIntegration({
    //   failedRequestStatusCodes: [400, 599],
    // }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
