import { Component } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Amplify, Hub } from 'aws-amplify';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';
import { LoggerService } from './services/logger/logger.service';
import { WebsocketService } from './services/websocket.service';
import { ZoomVideosdkService } from './services/zoom-videosdk.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private logger: LoggerService,
    private zoomvsdk: ZoomVideosdkService,
    public authenticator: AuthenticatorService,
    private wsService: WebsocketService,
    private authService: AuthService,
  ) {
    this.initAuth();
    this.zoomvsdk.createClient();
    Amplify.configure({ Auth: environment.auth });
  }

  initAuth() {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        this.authService.getUserAttributes('email').then((email) => Sentry.setUser({ email }));
      }
      if (payload.event === 'signOut') {
        this.wsService.disconnect();
      }
    });
    this.authService.getUserAttributes('email').then((email) => Sentry.setUser({ email }));
  }
}
