import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import ReconnectingWebSocket, { Options } from 'reconnecting-websocket';
import { from, Observable, of, Subject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IWebsocketMessage } from '@fastmatch/core/types/websocket';
import { LoggerService } from './logger/logger.service';

export const WS_ENDPOINT = environment.ws;

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private ws!: ReconnectingWebSocket;
  private socket$: Subject<any> | undefined;
  private _isConnected$ = new Subject<boolean>();
  public message$: Subject<IWebsocketMessage> = new Subject<IWebsocketMessage>();
  public isConnected$ = this._isConnected$.asObservable().pipe(filter((e) => !!e));
  constructor(private logger: LoggerService) {}

  public async connect(eventId: string) {
    if (!this.socket$ || this.socket$.closed) {
      const email = await this.getUserEmail();
      this.socket$ = this.createObservableSocket(
        WS_ENDPOINT + '?p=' + btoa(JSON.stringify({ uid: email, eid: eventId })),
      );
    }
  }

  public disconnect() {
    this.ws?.close();
    this.socket$ = undefined;
  }

  public sendMessage(msg: IWebsocketMessage) {
    this.ws.send(JSON.stringify(msg));
  }

  private createObservableSocket(url: string): Subject<any> {
    const options: Options = { debug: false };
    this.ws = new ReconnectingWebSocket(url, [], options);
    (window as any).ws = this.ws;

    new Observable<IWebsocketMessage>((observer) => {
      this.ws.onmessage = (event) => observer.next(JSON.parse(event.data));
      this.ws.onerror = (event) => {
        this.logger.error('Websocket error: ', event);
        observer.error(event);
      };
      this.ws.onclose = (event) => {
        this.logger.debug('Websocket connection closed');
        this._isConnected$.next(false);
        // observer.complete();
      };

      this.ws.onopen = (event) => {
        this.logger.debug('Websocket connection opened');
        this._isConnected$.next(true);
        return () => {
          this.logger.log('observable socket observer returned');
        };
      };
    }).subscribe((data) => {
      // this.logger.debug('Payload received: ', data);
      this.message$.next(data);
    });

    return this.message$;
  }

  private getIdToken() {
    return from(Auth.currentSession()).pipe(
      switchMap((session) => {
        return of(session.getIdToken().getJwtToken());
      }),
    );
  }

  private async getUserEmail() {
    const user = await Auth.currentUserInfo();
    return user.attributes.email;
  }
}
